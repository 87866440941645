import { useEffect, useState } from "react";
import { useStore } from "@/store";

export const usePostalCode = () => {
  const globalPostalCode = useStore((state) => state.globalPostalCode);
  const globalHouseNumber = useStore((state) => state.globalHouseNumber);
  const setGlobalLocation = useStore((state) => state.setGlobalLocation);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPostalCodeData = async () => {
      if (globalPostalCode && globalHouseNumber) {
        setLoading(true);
        setError(null);
        try {
          const response = await fetch("/api/postalcode", {
            method: "POST",
            body: JSON.stringify({
              data: {
                postalcode: globalPostalCode.replaceAll(" ", "").toUpperCase(),
                housenumber: globalHouseNumber.replaceAll(" ", ""),
              },
            }),
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (!response.ok) {
            throw new Error("Failed to fetch postal code data.");
          }

          const data = await response.json();
          setGlobalLocation(data.data);
        } catch (err: any) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPostalCodeData();
  }, [globalPostalCode, globalHouseNumber, setGlobalLocation]);

  return { loading, error };
};
